//https://ionicframework.com/docs/theming/colors
:root {
  --colorTema1: #0096d1;
  --colorTemaOffline: #FF9249;
  --colorTema2: #f2f5f7;
  --colorMenuActivo: #e1e2e8;
  --colorFondoFavoritos: rgb(49, 214, 137);
  --colorFondoLista: rgb(38, 123, 235);
  --colorFavoritos: #ffC977;
  --colorCorrecto: #32a800;
  --colorIncorrecto: #ff4242;
  --colorSinAsignar: #ccc;
  --colorCrearParte: #9b0000;
  --color-333: #333333;

  //https://ionicframework.com/docs/theming/colors#new-color-creator
  --ion-color-colorTema1: #0096d1;
  --ion-color-colorTema1-rgb: 0, 150, 209;
  --ion-color-colorTema1-contrast: #ffffff;
  --ion-color-colorTema1-contrast-rgb: 255, 255, 255;
  --ion-color-colorTema1-shade: #0084b8;
  --ion-color-colorTema1-tint: #1aa1d6;

  --ion-color-colorTemaOffline: #FF9249;
  --ion-color-colorTemaOffline-rgb: 255, 146, 73;
  --ion-color-colorTemaOffline-contrast: #000000;
  --ion-color-colorTemaOffline-contrast-rgb: 0, 0, 0;
  --ion-color-colorTemaOffline-shade: #e08040;
  --ion-color-colorTemaOffline-tint: #ff9d5b;

  --ion-color-colorTema2: #f2f5f7;
  --ion-color-colorTema2-rgb: 242, 245, 247;
  --ion-color-colorTema2-contrast: #000000;
  --ion-color-colorTema2-contrast-rgb: 0, 0, 0;
  --ion-color-colorTema2-shade: #d5d8d9;
  --ion-color-colorTema2-tint: #f3f6f8;

  --ion-color-colorMenuActivo: #e1e2e8;
  --ion-color-colorMenuActivo-rgb: 225, 226, 232;
  --ion-color-colorMenuActivo-contrast: #000000;
  --ion-color-colorMenuActivo-contrast-rgb: 0, 0, 0;
  --ion-color-colorMenuActivo-shade: #c6c7cc;
  --ion-color-colorMenuActivo-tint: #e4e5ea;

  --ion-color-colorFondoFavoritos: #31d689;
  --ion-color-colorFondoFavoritos-rgb: 49, 214, 137;
  --ion-color-colorFondoFavoritos-contrast: #000000;
  --ion-color-colorFondoFavoritos-contrast-rgb: 0, 0, 0;
  --ion-color-colorFondoFavoritos-shade: #2bbc79;
  --ion-color-colorFondoFavoritos-tint: #46da95;

  --ion-color-colorFondoLista: #267beb;
  --ion-color-colorFondoLista-rgb: 38, 123, 235;
  --ion-color-colorFondoLista-contrast: #ffffff;
  --ion-color-colorFondoLista-contrast-rgb: 255, 255, 255;
  --ion-color-colorFondoLista-shade: #216ccf;
  --ion-color-colorFondoLista-tint: #3c88ed;

  --ion-color-colorFavoritos: #ffC977;
  --ion-color-colorFavoritos-rgb: 255, 201, 119;
  --ion-color-colorFavoritos-contrast: #000000;
  --ion-color-colorFavoritos-contrast-rgb: 0, 0, 0;
  --ion-color-colorFavoritos-shade: #e0b169;
  --ion-color-colorFavoritos-tint: #ffce85;

  --ion-color-colorCorrecto: #32a800;
  --ion-color-colorCorrecto-rgb: 50, 168, 0;
  --ion-color-colorCorrecto-contrast: #ffffff;
  --ion-color-colorCorrecto-contrast-rgb: 255, 255, 255;
  --ion-color-colorCorrecto-shade: #2c9400;
  --ion-color-colorCorrecto-tint: #47b11a;

  --ion-color-colorIncorrecto: #ff4242;
  --ion-color-colorIncorrecto-rgb: 255, 66, 66;
  --ion-color-colorIncorrecto-contrast: #ffffff;
  --ion-color-colorIncorrecto-contrast-rgb: 255, 255, 255;
  --ion-color-colorIncorrecto-shade: #e03a3a;
  --ion-color-colorIncorrecto-tint: #ff5555;

  --ion-color-colorSinAsignar: #ccc;
  --ion-color-colorSinAsignar-rgb: 204, 204, 204;
  --ion-color-colorSinAsignar-contrast: #000000;
  --ion-color-colorSinAsignar-contrast-rgb: 0, 0, 0;
  --ion-color-colorSinAsignar-shade: #b4b4b4;
  --ion-color-colorSinAsignar-tint: #d1d1d1;

  --ion-color-colorCrearParte: #9b0000;
	--ion-color-colorCrearParte-rgb: 155,0,0;
	--ion-color-colorCrearParte-contrast: #ffffff;
	--ion-color-colorCrearParte-contrast-rgb: 255,255,255;
	--ion-color-colorCrearParte-shade: #880000;
	--ion-color-colorCrearParte-tint: #a51a1a;

}


.ion-color-dark {
  --ion-color-base: var(--ion-color-dark);
  --ion-color-base-rgb: var(--ion-color-dark-rgb);
  --ion-color-contrast: var(--ion-color-dark-contrast);
  --ion-color-contrast-rgb: var(--ion-color-dark-contrast-rgb);
  --ion-color-shade: var(--ion-color-dark-shade);
  --ion-color-tint: var(--ion-color-dark-tint);
}

.ion-color-colorTema1 {
  --ion-color-base: var(--ion-color-colorTema1);
  --ion-color-base-rgb: var(--ion-color-colorTema1-rgb);
  --ion-color-contrast: var(--ion-color-colorTema1-contrast);
  --ion-color-contrast-rgb: var(--ion-color-colorTema1-contrast-rgb);
  --ion-color-shade: var(--ion-color-colorTema1-shade);
  --ion-color-tint: var(--ion-color-colorTema1-tint);
}

.ion-color-colorTemaOffline {
  --ion-color-base: var(--ion-color-colorTemaOffline);
  --ion-color-base-rgb: var(--ion-color-colorTemaOffline-rgb);
  --ion-color-contrast: var(--ion-color-colorTemaOffline-contrast);
  --ion-color-contrast-rgb: var(--ion-color-colorTemaOffline-contrast-rgb);
  --ion-color-shade: var(--ion-color-colorTemaOffline-shade);
  --ion-color-tint: var(--ion-color-colorTemaOffline-tint);
}

.ion-color-colorTema2 {
  --ion-color-base: var(--ion-color-colorTema2);
  --ion-color-base-rgb: var(--ion-color-colorTema2-rgb);
  --ion-color-contrast: var(--ion-color-colorTema2-contrast);
  --ion-color-contrast-rgb: var(--ion-color-colorTema2-contrast-rgb);
  --ion-color-shade: var(--ion-color-colorTema2-shade);
  --ion-color-tint: var(--ion-color-colorTema2-tint);
}

.ion-color-colorMenuActivo {
  --ion-color-base: var(--ion-color-colorMenuActivo);
  --ion-color-base-rgb: var(--ion-color-colorMenuActivo-rgb);
  --ion-color-contrast: var(--ion-color-colorMenuActivo-contrast);
  --ion-color-contrast-rgb: var(--ion-color-colorMenuActivo-contrast-rgb);
  --ion-color-shade: var(--ion-color-colorMenuActivo-shade);
  --ion-color-tint: var(--ion-color-colorMenuActivo-tint);
}

.ion-color-colorFondoFavoritos {
  --ion-color-base: var(--ion-color-colorFondoFavoritos);
  --ion-color-base-rgb: var(--ion-color-colorFondoFavoritos-rgb);
  --ion-color-contrast: var(--ion-color-colorFondoFavoritos-contrast);
  --ion-color-contrast-rgb: var(--ion-color-colorFondoFavoritos-contrast-rgb);
  --ion-color-shade: var(--ion-color-colorFondoFavoritos-shade);
  --ion-color-tint: var(--ion-color-colorFondoFavoritos-tint);
}

.ion-color-colorFondoLista {
  --ion-color-base: var(--ion-color-colorFondoLista);
  --ion-color-base-rgb: var(--ion-color-colorFondoLista-rgb);
  --ion-color-contrast: var(--ion-color-colorFondoLista-contrast);
  --ion-color-contrast-rgb: var(--ion-color-colorFondoLista-contrast-rgb);
  --ion-color-shade: var(--ion-color-colorFondoLista-shade);
  --ion-color-tint: var(--ion-color-colorFondoLista-tint);
}

.ion-color-colorFavoritos {
  --ion-color-base: var(--ion-color-colorFavoritos);
  --ion-color-base-rgb: var(--ion-color-colorFavoritos-rgb);
  --ion-color-contrast: var(--ion-color-colorFavoritos-contrast);
  --ion-color-contrast-rgb: var(--ion-color-colorFavoritos-contrast-rgb);
  --ion-color-shade: var(--ion-color-colorFavoritos-shade);
  --ion-color-tint: var(--ion-color-colorFavoritos-tint);
}

.ion-color-colorCorrecto {
  --ion-color-base: var(--ion-color-colorCorrecto);
  --ion-color-base-rgb: var(--ion-color-colorCorrecto-rgb);
  --ion-color-contrast: var(--ion-color-colorCorrecto-contrast);
  --ion-color-contrast-rgb: var(--ion-color-colorCorrecto-contrast-rgb);
  --ion-color-shade: var(--ion-color-colorCorrecto-shade);
  --ion-color-tint: var(--ion-color-colorCorrecto-tint);
}

.ion-color-colorIncorrecto {
  --ion-color-base: var(--ion-color-colorIncorrecto);
  --ion-color-base-rgb: var(--ion-color-colorIncorrecto-rgb);
  --ion-color-contrast: var(--ion-color-colorIncorrecto-contrast);
  --ion-color-contrast-rgb: var(--ion-color-colorIncorrecto-contrast-rgb);
  --ion-color-shade: var(--ion-color-colorIncorrecto-shade);
  --ion-color-tint: var(--ion-color-colorIncorrecto-tint);
}

.ion-color-colorSinAsignar {
  --ion-color-base: var(--ion-color-colorSinAsignar);
  --ion-color-base-rgb: var(--ion-color-colorSinAsignar-rgb);
  --ion-color-contrast: var(--ion-color-colorSinAsignar-contrast);
  --ion-color-contrast-rgb: var(--ion-color-colorSinAsignar-contrast-rgb);
  --ion-color-shade: var(--ion-color-colorSinAsignar-shade);
  --ion-color-tint: var(--ion-color-colorSinAsignar-tint);
}

.ion-color-colorCrearParte {
  --ion-color-base: var(--ion-color-colorCrearParte);
  --ion-color-base-rgb: var(--ion-color-colorCrearParte-rgb);
  --ion-color-contrast: var(--ion-color-colorCrearParte-contrast);
  --ion-color-contrast-rgb: var(--ion-color-colorCrearParte-contrast-rgb);
  --ion-color-shade: var(--ion-color-colorCrearParte-shade);
  --ion-color-tint: var(--ion-color-colorCrearParte-tint);
}

//todo App2u: reordenar una mica aixo, aquests estils estaven al app.component pero realment son globals

// http://ionicframework.com/docs/theming/
// App Global Sass
// --------------------------------------------------
// Put style rules here that you want to apply globally. These
// styles are for the entire app and not just one component.
// Additionally, this file can be also used as an entry point
// to import other Sass files to be included in the output CSS.
//
// Shared Sass variables, which can be used to adjust Ionic's
// default Sass variables, belong in "theme/variables.scss".
//
// To declare rules for a specific mode, create a child rule
// for the .md, .ios, or .wp mode classes. The mode class is
// automatically applied to the <body> element in the app.
.icon_menu_edit {
  width: 20px !important;
}

.icon_menu {
  width: 30px !important;
}

.filter-withe {
  filter: brightness(0) invert(1);
}

.toolbar {
  padding-top: 4px !important;
}

.align-middle-vertical {
  display: inline-block;
  vertical-align: middle;
}


/* Menú */

.background-menu {
  background: var(--colorTema2);
}

.background-menu .text-usuario {
  font-size: 1.5rem;
  font-style: italic;
}

.grupo-opciones-menu {
  margin: 16px 0 0 16px;
  font-style: italic;
  font-size: 1.4rem;
}

.icono-menu-usuario-login {
  font-size: 2.6rem;
  margin-right: 0.3rem;
}

.active-page-menu {
  --ion-background-color: var(--colorMenuActivo);
}

/* Animación de cargando */
.lc-loader-container {  
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-right: 5px;
  min-height: 10vh;
  min-width: 30vw;
  padding: 1rem;
}
.lc-loader-animation {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background: 
    radial-gradient(farthest-side,#2851F4 94%,#0000) top/4px 4px no-repeat,
    conic-gradient(#0000 30%,#2851F4);
  -webkit-mask: radial-gradient(farthest-side,#0000 calc(100% - 4px),#000 0);
  animation:lc-loader-s3 1s infinite linear;
  margin-bottom: 1rem;
}
@keyframes lc-loader-s3{ 
  100%{transform: rotate(1turn)}
}


/*
.loading-md .loading-wrapper {
  max-width: 25rem !important;
  min-width: 25rem !important;
  justify-content: center;
}

.loadingImg {
  max-width: 8rem !important;
  display: block;
  margin-left: auto;
  margin-right: auto;
}*/

.icon-align-middle {
  vertical-align: middle;
}

.icon-align-text-bottom {
  vertical-align: text-bottom;
}

.margin-vertical {
  margin: 8px 0;
}

.text-no-existe {
  font-style: italic;
}

.tabla-fixed {
  width: 100%;
  table-layout: fixed;
}

.header-elements {
  background-color: #F2F6FC;

  ion-label {
    margin: 13px 8px 13px 0;
  }

}

ion-card-header {
  font-weight: 600;
  color: var(--secondary);
  width: 100%;
  display: inline-block;
}

.text-detalle-parte {
  font-size: 0.9em;
}

label,
ion-label,
.label-md,
.label-ios {
  white-space: normal;
}

.card-no-margin-horiz {
  margin: 10px 5px;
  width: calc(100% - 10px);
}

@media only screen {
  .modal-wrapper {
    position: absolute;
    top: 0 !important;
    left: 0 !important;
    display: block;
    width: 100% !important;
    height: 100% !important;
  }
}

//
.validationError {
  color: #ff4242;
  font-style: italic;
}

ion-toolbar[hidebackbutton] button[menutoggle] {
  display: block !important;
}


/** respuesta fase **/

.content .footer-rf {
  position: absolute;
  bottom: 0px;
  width: 100%;
  min-height: 5rem;
  background: #fff;
}

.sub-footer-rf {
  padding-right: 1rem;
}

.sin-movimiento-rf {
  color: #777;
}

.con-movimiento-rf {
  color: #000;
}

//connection status
.conectado {
  color: white;
  padding-right: 1rem;
}

.desconectado {
  color: white;
  padding-right: 1rem;
}

.conectado-icon {
  background-color: var(--colorTema1) !important;
}

.desconectado-icon {
  background-color: var(--colorTemaOffline) !important;
  padding-top: 6px !important;
}

.conectado-chip {
  background-color: var(--colorTema1) !important;
}

.desconectado-chip {
  background-color: var(--colorTemaOffline) !important;
}

// @media screen and (orientation:landscape) {
//     .set-fav {
//         top: 10px;
//         right: 10px;
//         right: calc(10px + constant(safe-area-inset-right));
//         right: calc(10px + env(safe-area-inset-right));
//     }
// }
// @media screen and (orientation:portrait) {
//     .set-fav {
//         bottom: 10px;
//         right: 10px;
//         right: calc(10px + constant(safe-area-inset-right));
//         right: calc(10px + env(safe-area-inset-right));
//     }
// }

/** toolbar */

.toolbar-title {
  overflow: visible;
  position: relative;
}

.toolbar-title-default.md {
  .subtitle {
    font-size: 66%;
    margin-top: 0;
    margin-bottom: 0;
  }
}

.toolbar-title-default.ios {
  .subtitle {
    font-size: 66%;
    text-align: center;
    width: 100%;
    margin-top: 0;
  }
}

html {
  font-size: 10px;
}

.when-high-resolution {
  display: inline;
}

.when-low-resolution {
  display: none;
}

.yes-tablet {
  display: inline;
}

.no-tablet {
  display: none;
}

@media (max-width: 481px) {
  .when-high-resolution {
    display: none;
  }
  .when-low-resolution {
    display: inline;
  }
}

@media (max-width: 481px) {
  .yes-tablet {
    display: none;
  }
  .no-tablet {
    display: inline;
  }
}

@media (max-width: 28rem) {
  .when-high-resolution {
    display: none;
  }
  .when-low-resolution {
    display: inline;
  }
}


/** fin toolbar */

::-webkit-scrollbar {
  width: 0px;
  background: transparent;
}

//@import "./theme/icons"


ion-button::part(native) {
  padding: 0 !important;
  padding-inline-end: 0.5rem !important;
  padding-inline-start: 0.5rem !important;
}


// Make modal full screen on big screens
.modal-fullscreen {
  --width: 100% !important;
  --height: 100% !important;
  ion-header ion-toolbar:first-of-type {
    // add padding to the top
    padding-top: env(safe-area-inset-top);
  }

}

.icon-padding-en-chip{
  margin-right: 0;
}

ion-back-button{
  margin-left: 5px;
}

.color-333{
  color: var(--color-333);
}