/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import '~@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";
@import "src/app/theme/colors.scss";

@media screen and (min-width: 1200px) {
  .hide-when-big-screen {
    display: none;
  }
}

ion-app{
  font-size: 1.4rem;
}

// Add a border to the top of the cancel button so it looks different from the states
.action-sheet-group-cancel.sc-ion-action-sheet-md{
  border-top : 1px solid #b3b3b3 !important;
}

// set the minimum height of the textarea in the part alert
#alert-observations-part-input{
  min-height: 100px;
}

// set the minimum width of the alert
.alert-observations-part .alert-wrapper{
  --min-width: min(90%, 350px);
}
.alert-observations-part .alert-input.sc-ion-alert-md {
  width: 100%;
  border: 1px solid var(--ion-color-step-250, #bfbfbf);
  border-radius: 4px 4px 0 0;
  padding: 8px 0;
  outline: none;
  background: transparent;
}

.alert-observations-part .alert-input.sc-ion-alert-md:focus {
  border-bottom: 2px solid var(--ion-color-primary, #007bff);
}



.no-value {
  color: var(--no-value-color);
  font-weight: bold;
  font-style: italic;
}

.no-value-color {
  color: var(--no-value-color);
}

.image-with-loader {
  display: flex;
  flex-direction: column;
  align-items: center;
}

/* Helpers para margin copiadas de Prime Flex: https://primeflex.org/margin*/
.m-0  {margin: 0;}
.m-1  {margin: 0.25rem;}
.m-2  {margin: 0.5rem;}
.m-3  {margin: 1rem;}
.m-4  {margin: 1.5rem;}
.m-5  {margin: 2rem;}
.m-6  {margin: 3rem;}
.m-7  {margin: 4rem;}
.m-8  {margin: 5rem;}
.mb-0  {margin-bottom: 0;}
.mb-1  {margin-bottom: 0.25rem;}
.mb-2  {margin-bottom: 0.5rem;}
.mb-3  {margin-bottom: 1rem;}
.mb-4  {margin-bottom: 1.5rem;}
.mb-5  {margin-bottom: 2rem;}
.mb-6  {margin-bottom: 3rem;}
.mb-7  {margin-bottom: 4rem;}
.mb-8  {margin-bottom: 5rem;}
.mb-auto  {margin-bottom: auto;}
.ml-0  {margin-left: 0;}
.ml-1  {margin-left: 0.25rem;}
.ml-2  {margin-left: 0.5rem;}
.ml-3  {margin-left: 1rem;}
.ml-4  {margin-left: 1.5rem;}
.ml-5  {margin-left: 2rem;}
.ml-6  {margin-left: 3rem;}
.ml-7  {margin-left: 4rem;}
.ml-8  {margin-left: 5rem;}
.ml-auto  {margin-left: auto;}
.mr-0  {margin-right: 0;}
.mr-1  {margin-right: 0.25rem;}
.mr-2  {margin-right: 0.5rem;}
.mr-3  {margin-right: 1rem;}
.mr-4  {margin-right: 1.5rem;}
.mr-5  {margin-right: 2rem;}
.mr-6  {margin-right: 3rem;}
.mr-7  {margin-right: 4rem;}
.mr-8  {margin-right: 5rem;}
.mr-auto  {margin-right: auto;}
.mt-0  {margin-top: 0;}
.mt-1  {margin-top: 0.25rem;}
.mt-2  {margin-top: 0.5rem;}
.mt-3  {margin-top: 1rem;}
.mt-4  {margin-top: 1.5rem;}
.mt-5  {margin-top: 2rem;}
.mt-6  {margin-top: 3rem;}
.mt-7  {margin-top: 4rem;}
.mt-8  {margin-top: 5rem;}
.mx-0  {margin-left: 0;margin-right: 0;}
.mx-1  {margin-left: 0.25rem;margin-right: 0.25rem;}
.mx-2  {margin-left: 0.5rem;margin-right: 0.5rem;}
.mx-3  {margin-left: 1rem;margin-right: 1rem;}
.mx-4  {margin-left: 1.5rem;margin-right: 1.5rem;}
.mx-5  {margin-left: 2rem;margin-right: 2rem;}
.mx-6  {margin-left: 3rem;margin-right: 3rem;}
.mx-7  {margin-left: 4rem;margin-right: 4rem;}
.mx-8  {margin-left: 5rem;margin-right: 5rem;}
.my-0  {margin-top: 0;margin-bottom: 0;}
.my-1  {margin-top: 0.25rem;margin-bottom: 0.25rem;}
.my-2  {margin-top: 0.5rem;margin-bottom: 0.5rem;}
.my-3  {margin-top: 1rem;margin-bottom: 1rem;}
.my-4  {margin-top: 1.5rem;margin-bottom: 1.5rem;}
.my-5  {margin-top: 2rem;margin-bottom: 2rem;}
.my-6  {margin-top: 3rem;margin-bottom: 3rem;}
.my-7  {margin-top: 4rem;margin-bottom: 4rem;}
.my-8  {margin-top: 5rem;margin-bottom: 5rem;}

/* Helpers para padding copiadas de Prime Flex: https://primeflex.org/padding */
.p-0  {padding: 0;}
.p-1  {padding: 0.25rem;}
.p-2  {padding: 0.5rem;}
.p-3  {padding: 1rem;}
.p-4  {padding: 1.5rem;}
.p-5  {padding: 2rem;}
.p-6  {padding: 3rem;}
.p-7  {padding: 4rem;}
.p-8  {padding: 5rem;}
.pb-0  {padding-bottom: 0;}
.pb-1  {padding-bottom: 0.25rem;}
.pb-2  {padding-bottom: 0.5rem;}
.pb-3  {padding-bottom: 1rem;}
.pb-4  {padding-bottom: 1.5rem;}
.pb-5  {padding-bottom: 2rem;}
.pb-6  {padding-bottom: 3rem;}
.pb-7  {padding-bottom: 4rem;}
.pb-8  {padding-bottom: 5rem;}
.pl-0  {padding-left: 0;}
.pl-1  {padding-left: 0.25rem;}
.pl-2  {padding-left: 0.5rem;}
.pl-3  {padding-left: 1rem;}
.pl-4  {padding-left: 1.5rem;}
.pl-5  {padding-left: 2rem;}
.pl-6  {padding-left: 3rem;}
.pl-7  {padding-left: 4rem;}
.pl-8  {padding-left: 5rem;}
.pr-0  {padding-right: 0;}
.pr-1  {padding-right: 0.25rem;}
.pr-2  {padding-right: 0.5rem;}
.pr-3  {padding-right: 1rem;}
.pr-4  {padding-right: 1.5rem;}
.pr-5  {padding-right: 2rem;}
.pr-6  {padding-right: 3rem;}
.pr-7  {padding-right: 4rem;}
.pr-8  {padding-right: 5rem;}
.pt-0  {padding-top: 0;}
.pt-1  {padding-top: 0.25rem;}
.pt-2  {padding-top: 0.5rem;}
.pt-3  {padding-top: 1rem;}
.pt-4  {padding-top: 1.5rem;}
.pt-5  {padding-top: 2rem;}
.pt-6  {padding-top: 3rem;}
.pt-7  {padding-top: 4rem;}
.pt-8  {padding-top: 5rem;}
.px-0  {padding-left: 0;padding-right: 0;}
.px-1  {padding-left: 0.25rem;padding-right: 0.25rem;}
.px-2  {padding-left: 0.5rem;padding-right: 0.5rem;}
.px-3  {padding-left: 1rem;padding-right: 1rem;}
.px-4  {padding-left: 1.5rem;padding-right: 1.5rem;}
.px-5  {padding-left: 2rem;padding-right: 2rem;}
.px-6  {padding-left: 3rem;padding-right: 3rem;}
.px-7  {padding-left: 4rem;padding-right: 4rem;}
.px-8  {padding-left: 5rem;padding-right: 5rem;}
.py-0  {padding-top: 0;padding-bottom: 0;}
.py-1  {padding-top: 0.25rem;padding-bottom: 0.25rem;}
.py-2  {padding-top: 0.5rem;padding-bottom: 0.5rem;}
.py-3  {padding-top: 1rem;padding-bottom: 1rem;}
.py-4  {padding-top: 1.5rem;padding-bottom: 1.5rem;}
.py-5  {padding-top: 2rem;padding-bottom: 2rem;}
.py-6  {padding-top: 3rem;padding-bottom: 3rem;}
.py-7  {padding-top: 4rem;padding-bottom: 4rem;}
.py-8  {padding-top: 5rem;padding-bottom: 5rem;}




