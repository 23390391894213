// Ionic Variables and Theming. For more info, please see:
// http://ionicframework.com/docs/theming/

/** Ionic CSS Variables **/
:root {
  --ion-color-primary: #5595a7;
  --ion-color-primary-rgb: 85, 149, 167;
  --ion-color-secondary-contrast: #ffffff;
  --ion-color-secondary-contrast-rgb: 255, 255, 255;
  --ion-color-primary-shade: #4b8393;
  --ion-color-primary-tint: #66a0b0;

  --ion-color-secondary: #585b68;
  --ion-color-secondary-rgb: 88, 91, 104;
  --ion-color-secondary-contrast: #ffffff;
  --ion-color-secondary-contrast-rgb: 255, 255, 255;
  --ion-color-secondary-shade: #4d505c;
  --ion-color-secondary-tint: #696b77;

  --ion-color-tertiary: #93a417;
  --ion-color-tertiary-rgb: 147, 164, 23;
  --ion-color-tertiary-contrast: #000000;
  --ion-color-tertiary-contrast-rgb: 0, 0, 0;
  --ion-color-tertiary-shade: #819014;
  --ion-color-tertiary-tint: #9ead2e;

  --ion-color-success: #32a800;
  --ion-color-success-rgb: 50, 168, 0;
  --ion-color-success-contrast: #ffffff;
  --ion-color-success-contrast-rgb: 255, 255, 255;
  --ion-color-success-shade: #2c9400;
  --ion-color-success-tint: #47b11a;

  --ion-color-warning: #ffc409;
  --ion-color-warning-rgb: 255, 196, 9;
  --ion-color-warning-contrast: #000000;
  --ion-color-warning-contrast-rgb: 0, 0, 0;
  --ion-color-warning-shade: #e0ac08;
  --ion-color-warning-tint: #ffca22;

  --ion-color-danger: #f53d3d;
  --ion-color-danger-rgb: 245, 61, 61;
  --ion-color-danger-contrast: #ffffff;
  --ion-color-danger-contrast-rgb: 255, 255, 255;
  --ion-color-danger-shade: #d83636;
  --ion-color-danger-tint: #f65050;

  --ion-color-dark: #1e1e1e;
  --ion-color-dark-rgb: 30, 30, 30;
  --ion-color-dark-contrast: #ffffff;
  --ion-color-dark-contrast-rgb: 255, 255, 255;
  --ion-color-dark-shade: #1a1a1a;
  --ion-color-dark-tint: #353535;

  --ion-color-medium: #92949c;
  --ion-color-medium-rgb: 146, 148, 156;
  --ion-color-medium-contrast: #000000;
  --ion-color-medium-contrast-rgb: 0, 0, 0;
  --ion-color-medium-shade: #808289;
  --ion-color-medium-tint: #9d9fa6;

  --ion-color-light: #f4f4f4;
  --ion-color-light-rgb: 244, 244, 244;
  --ion-color-light-contrast: #000000;
  --ion-color-light-contrast-rgb: 0, 0, 0;
  --ion-color-light-shade: #d7d7d7;
  --ion-color-light-tint: #f5f5f5;

  --ion-background-color: #ffffff;
	--ion-background-color-rgb: 255,255,255;

	--ion-text-color: #333333;
	--ion-text-color-rgb: 51,51,51;

	--ion-color-step-50: #f5f5f5;
	--ion-color-step-100: #ebebeb;
	--ion-color-step-150: #e0e0e0;
	--ion-color-step-200: #d6d6d6;
	--ion-color-step-250: #cccccc;
	--ion-color-step-300: #c2c2c2;
	--ion-color-step-350: #b8b8b8;
	--ion-color-step-400: #adadad;
	--ion-color-step-450: #a3a3a3;
	--ion-color-step-500: #999999;
	--ion-color-step-550: #8f8f8f;
	--ion-color-step-600: #858585;
	--ion-color-step-650: #7a7a7a;
	--ion-color-step-700: #707070;
	--ion-color-step-750: #666666;
	--ion-color-step-800: #5c5c5c;
	--ion-color-step-850: #525252;
	--ion-color-step-900: #474747;
	--ion-color-step-950: #3d3d3d;

}
